:root {
	--bg-color: #FCFCFC;
	--fg-color: #5D576B;
  --font-color: #333333;
	--accent-bg-color: #F7567C;
	--accent-fg-color: #FCFCFC;
  --font-interface: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: var(--bg-color);
  color: var(--font-color);
  font-size: 1.2em;
  font-family: var(--font-interface);
}

* {
  box-sizing: border-box;
}


@keyframes appear {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

a, a:visited {
  color: var(--accent-bg-color);
}

.animation_appear {
  animation-name: var(--animation-type);
  animation-duration: 0.7s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
