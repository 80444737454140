.sk_cube_grid {
  width: 40px;
  height: 40px;
}

.sk_cube_grid div {
  width: 33%;
  height: 33%;
  background-color: var(--fg-color);
  float: left;
  animation-name: sk-cubeGridScaleDelay;
  animation-iteration-count: infinite;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;
}

.sk_cube1 { animation-delay: 0.2s; }
.sk_cube2 { animation-delay: 0.3s; }
.sk_cube3 { animation-delay: 0.4s; }
.sk_cube4 { animation-delay: 0.1s; }
.sk_cube5 { animation-delay: 0.2s; }
.sk_cube6 { animation-delay: 0.3s; }
.sk_cube7 { animation-delay: 0s; }
.sk_cube8 { animation-delay: 0.1s; }
.sk_cube9 { animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  } 35% {
    transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  } 35% {
    transform: scale3D(0, 0, 1);
  } 
}
