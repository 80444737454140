@font-face {
  font-family: 'OpenDyslexic';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url(OpenDyslexic-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: url(OpenDyslexic-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url(OpenDyslexic-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: url(OpenDyslexic-Bold-Italic.woff2) format('woff2');
}
